import { ApiResponse } from '../../interfaces/api-response.interface';
import { AuthService } from '../auth/auth.service';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { URL_SERVICES } from '../../config/config';
import { Bill } from '../../models/bill.model';
import { BillDetail } from '../../models/bill-detail.model';
import { Payment } from '../../models/payment.model';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  getBills(warehouseId: string | null, accountId: string | null, limit: number | null, page: number, term: string = '', daysSinceCreated: string = ''): Observable<ApiResponse<Bill[]>> {
    let params = new HttpParams().set('page', page.toString());

    if (warehouseId) params = params.set('warehouseId', warehouseId);
    if (accountId) params = params.set('accountId', accountId);
    if (term) params = params.set('term', term);
    if (daysSinceCreated) params = params.set('daysSinceCreated', daysSinceCreated);
    if (limit) params = params.set('limit', limit.toString());

    const headers = this.authService.getHeaders();
    const url = `${URL_SERVICES}/billing/bills`;

    return this.http.get<ApiResponse<Bill[]>>(url, { headers, params }).pipe(
      map(response => this.mapBillsResponse(response)),
      catchError(error => throwError(() => new Error('Error al cargar las facturas')))
    );
  }

  getPendingBills(): Observable<ApiResponse<Bill[]>> {
    const url = `${URL_SERVICES}/billing/pending-bills`;
    const headers = this.authService.getHeaders();

    return this.http.get<ApiResponse<Bill[]>>(url, { headers }).pipe(
      map(response => this.mapBillsResponse(response)),
      catchError(error => throwError(() => new Error('Error al cargar las facturas pendientes')))
    );
  }

  getOverdueBills(): Observable<ApiResponse<Bill[]>> {
    const url = `${URL_SERVICES}/billing/overdue-bills`;
    const headers = this.authService.getHeaders();

    return this.http.get<ApiResponse<Bill[]>>(url, { headers }).pipe(
      map(response => {
        // console.log('Respuesta del servidor (facturas vencidas):', response);  // Agregar un log para verificar la respuesta del servidor
        return this.mapBillsResponse(response);
      }),
      catchError(error => throwError(() => new Error('Error al cargar las facturas vencidas')))
    );
  }


  payBill(billId: string, value: number, currency: string, stripeToken: string): Observable<ApiResponse<Bill>> {
    const url = `${URL_SERVICES}/payment/pay`;
    const headers = this.authService.getHeaders();
    const body = {
      billId,
      value,
      currency,
      stripeToken,
      paymentPlatformId: '4fba7c62-0c8d-4b85-889d-5357ecf2f9c7'
    };
    return this.http.post<ApiResponse<Bill>>(url, body, { headers }).pipe(
      map(response => {
    //    console.log('Respuesta del servidor:', response);
        if (!response.status) {
          throw new Error(response.message || 'El pago ha fallado');
        }
        return this.mapBillResponse(response);
      }),
      catchError(error => {
        console.error('Error en el procesamiento del pago:', error);
        // Puedes agregar más detalles del error, por ejemplo:
        if (error instanceof HttpErrorResponse) {
          console.error('Detalles del error de respuesta HTTP:', error.error);
        }
        return throwError(() => new Error('El procesamiento del pago ha fallado'));
      })
    );
  }


  private mapBillsResponse(response: ApiResponse<Bill[]>): ApiResponse<Bill[]> {
    if (!response.status) {
      throw new Error(response.message);
    }
    return {
      ...response,
      data: response.data.map(bill => {
        return new Bill(
          bill.id,
          bill.warehouseId,
          bill.accountId,
          bill.period,
          bill.description,
          bill.amount_due,
          bill.tax_amount,
          bill.discount,
          bill.subtotal,
          bill.currency,
          new Date(bill.dueDate),
          bill.status,
          new Date(bill.createdAt),
          new Date(bill.updatedAt),
          bill.details.map(detail => new BillDetail(
            detail.id,
            detail.billId,
            detail.description,
            detail.amount,
            detail.tax,
            detail.total,
            new Date(detail.createdAt),
            new Date(detail.updatedAt)
          )),
          bill.payments.map(payment => new Payment(
            payment.id,
            payment.bill_id,
            payment.account_id,
            payment.warehouse_id,
            payment.payment_platform_id,
            payment.description,
            payment.amount,
            payment.status,
            payment.transaction_id,
            payment.payment_response,
            new Date(payment.created_at),
            new Date(payment.updated_at)
          ))
        );
      })
    };
  }

  private mapBillResponse(response: ApiResponse<Bill>): ApiResponse<Bill> {
    if (!response.status) {
      throw new Error(response.message);
    }

    const bill = response.data;
    return {
      ...response,
      data: new Bill(
        bill.id,
        bill.warehouseId,
        bill.accountId,
        bill.period,
        bill.description,
        bill.amount_due,
        bill.tax_amount,
        bill.discount,
        bill.subtotal,
        bill.currency,
        new Date(bill.dueDate),
        bill.status,
        new Date(bill.createdAt),
        new Date(bill.updatedAt),
        bill.details?.map(detail => new BillDetail(
          detail.id,
          detail.billId,
          detail.description,
          detail.amount,
          detail.tax,
          detail.total,
          new Date(detail.createdAt),
          new Date(detail.updatedAt)
        )) || [],
        bill.payments?.map(payment => new Payment(
          payment.id,
          payment.bill_id,
          payment.account_id,
          payment.warehouse_id,
          payment.payment_platform_id,
          payment.description,
          payment.amount,
          payment.status,
          payment.transaction_id,
          payment.payment_response,
          new Date(payment.created_at),
          new Date(payment.updated_at)
        )) || []
      )
    };
  }
}
